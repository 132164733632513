<template>
  <section id="service" class="main_padding-b">
    <div class="inner">
      <div class="detail_box">
        <swiper
          ref="detail_swiper"
          :options="detailSwiperOption">
            <swiper-slide
              v-for="(item, i) in indexServicePicSwiper"
              :key="`detail${i}`">
                <router-link :to="item.route" class="detail_item">
                  <figure
                    class="detail_pic"
                    :style="
                      `background-image: url(${item.pic})`
                    ">
                  </figure>
                  <div class="item_info">
                    <div class="left">
                      <img
                        :src="item.icon"
                        class="item_icon"
                        alt="">
                      <div class="item_text txt-white">
                        <p class="item_title">
                          <span class="h4 txt-bold">{{item.nameEn}} </span>
                          <span class="p-18">{{item.name}}</span>
                        </p>
                        <p>
                          {{item.info}}
                        </p>
                      </div>
                    </div>
                    <div class="arrow-circle"></div>
                  </div>
                </router-link>
            </swiper-slide>
        </swiper>
      </div>
      <div class="roll_box">
        <h2 class="txt-theme txt-an">
          <span>OUR </span>
          <span class="txt-bold">SERVICE</span>
        </h2>
        <h6 class="txt-bold">
          日商麒麟技術服務項目
        </h6>
        <swiper
          class="roll_swiper"
          ref="roll_swiper"
          :options="rollSwiperOption"
          @slideNextTransitionStart="rollOnChange">
            <swiper-slide
              v-for="(item, i) in rollSwiperData"
              :key="`roll${i}`">
                <div class="roll_item">
                  <figure
                    class="roll_pic"
                    :style="
                      `background-image: url(${item.pic})`
                    ">
                  </figure>
                  <h6 class="roll_text txt-white">
                    {{item.nameEn}}
                  </h6>
                </div>
            </swiper-slide>
        </swiper>
        <div class="txt-right">
          <div class="next h6">
            <h6>NEXT</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { indexServicePicSwiper } from '@/utils/const';

export default {
  name: 'Service',
  data() {
    return {
      indexServicePicSwiper,
      detailSwiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        autoplay: false,
        breakpoints: {
          1023: {
            allowTouchMove: true,
          },
        },
      },
      rollSwiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 15,
        allowTouchMove: false,
        autoplay: false,
        navigation: {
          nextEl: '#service .roll_box .next',
        },
      },
    };
  },
  computed: {
    detailSwiper() {
      return this.$refs.detail_swiper.swiper;
    },
    rollSwiper() {
      return this.$refs.roll_swiper.swiper;
    },
    rollSwiperData() {
      const arr = indexServicePicSwiper.filter((item, index) => index > 0);
      arr.push(indexServicePicSwiper[0]);
      return arr;
    },
  },
  methods: {
    rollOnChange() {
      this.detailSwiper.slideNext();
    },
  },
};
</script>
