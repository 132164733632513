<template>
  <section id="kv" ref="kv">
    <div
      class="bg bg01"
      :style="{
        backgroundImage: `url(${require('@/assets/img/index/01_kv1.png')})`
      }">
    </div>
    <div
      class="bg bg02"
      :style="{
        transform: `scale(${bgScale})`,
        backgroundImage: `url(${require('@/assets/img/index/01_kv2.png')})`
      }">
    </div>
    <div class="inner w1200">
      <div class="text">
        <h2 class="txt-theme txt-bold txt-an ls1">
          <span>For the next</span>
          <span> Generation</span>
        </h2>
        <h4 class="txt-theme">
          穩固技術基石 成就麒麟價值
        </h4>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Kv',
  components: {
  },
  data() {
    return {
      bgScale: 1,
      kvHeight: null,
    };
  },
  computed: {
    ...mapState([
      'scrollDistence',
    ]),
  },
  watch: {
    scrollDistence() {
      if (this.scrollDistence < this.kvHeight) {
        this.bgScale = 1 + this.scrollDistence / 3000;
      }
    },
  },
  mounted() {
    this.kvHeight = this.$refs.kv.offsetHeight;
  },
};
</script>
