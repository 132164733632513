<template>
  <section id="process" class="main_padding-t">
    <div class="w1200 txt-center">
      <h2 class="txt-theme txt-an">
        <span>SERVICE </span>
        <span class="txt-bold">PROCESS</span>
      </h2>
      <h6 class="txt-bold">
        服務流程
      </h6>
    </div>
    <img src="@/assets/img/index/04_r.svg" class="deco-r" alt="">
    <div class="main">
      <div class="inner w1200">
        <div class="item txt-white">
          <img src="@/assets/img/index/04_icon1.svg" alt="">
          <h6>整合方案</h6>
          <p>Integration Programme</p>
          <Btn
            class="item-btn"
            :to=" { name: 'IntegrationProgramme' } "
            :type="3"
            :text="'Learn More'"/>
        </div>
        <div class="item txt-white">
          <img src="@/assets/img/index/04_icon2.svg" alt="">
          <h6>成功關鍵</h6>
          <p>Key Points Of Success</p>
          <Btn
            class="item-btn"
            :to=" { name: 'KeyPoint' } "
            :type="3"
            :text="'Learn More'"/>
        </div>
        <div class="item txt-white">
          <img src="@/assets/img/index/04_icon3.svg" alt="">
          <h6>技術流程</h6>
          <p>Technique Process</p>
          <Btn
            class="item-btn"
            :to=" { name: 'TechniqueProcess' } "
            :type="3"
            :text="'Learn More'"/>
        </div>
      </div>
    </div>
    <img src="@/assets/img/index/04_l.svg" class="deco-l" alt="">
  </section>
</template>

<script>
import Btn from '@/components/public/Btn.vue';

export default {
  name: 'Process',
  components: {
    Btn,
  },
  data() {
    return {
    };
  },
};
</script>
