<template>
  <section id="index">
    <div id="loading">
      <img src="@/assets/img/public/01_header/logo.svg" class="loading_logo" alt="">
      <div class="left_to_right"></div>
    </div>
    <Kv/>
    <About/>
    <Service/>
    <Process/>
    <Achievement/>
    <Contact/>
  </section>
</template>

<script>
import Kv from '@/components/page/index/Kv.vue';
import About from '@/components/page/index/About.vue';
import Service from '@/components/page/index/Service.vue';
import Process from '@/components/page/index/Process.vue';
import Achievement from '@/components/page/index/Achievement.vue';
import Contact from '@/components/page/index/Contact.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Home',
  mixins: [titleMixin],
  components: {
    Kv,
    About,
    Service,
    Process,
    Achievement,
    Contact,
  },
};
</script>

<style
  scoped
  src="@/assets/scss/index.scss"
  lang="scss">
</style>
