<template>
  <section id="achievement" class="main_padding-t">
    <div class="inner">
      <div class="text">
        <h2 class="txt-theme txt-an">
          <span class="txt-bold">ACTUAL</span>
          <br>
          <span>ACHIEVEMENT</span>
        </h2>
        <h6 class="txt-bold">
          歷年實績
        </h6>
        <p>
          日商麒麟技術是工廠建設合作夥伴，從工廠建設的計畫階段到試運轉，全面地協助顧客，是間專業從事食品和製藥工廠的工程技術公司。
        </p>
        <Btn
          :to="{
            name: 'AchievementPast',
            params: { page: 1 },
          }"
          :type="1"
          :text="'Learn More'"/>
      </div>
    </div>
    <div class="pictures">
      <swiper
        :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in indexAchievementPicSwiper"
            :key="`indexAchievementPicSwiper${i}`">
              <figure
                class="achievement_pic"
                :style="`background-image: url(${item})`">
              </figure>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import Btn from '@/components/public/Btn.vue';
import { indexAchievementPicSwiper } from '@/utils/const';

export default {
  name: 'Achievement',
  components: {
    Btn,
  },
  data() {
    return {
      indexAchievementPicSwiper,
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#achievement .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed: {
  },
  watch: {
  },
};
</script>
