<template>
  <router-link
    :to="to"
    :class="[
      { 'btn' : type == 1 }, // 實色紅底
      { 'btn-border' : type == 2 }, // 邊框紅色 底色透明
      { 'btn non_arrow' : type == 3 }, // 實色紅底 沒有箭頭icon
    ]">
    <div class="circle">
      <div class="in">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.52" height="24.52" viewBox="0 0 24.52 24.52">
          <g transform="translate(-419 -2068)">
          <circle
            cx="12.26" cy="12.26" r="12.26"
            transform="translate(419 2068)"
            fill="none"/>
          <path
            d="M2715.789,1791.683l3.489,3.489-3.489,3.489"
            transform="translate(-2285.897 284.71)"
            fill="none"
            stroke="" stroke-width="1.5"/>
          </g>
        </svg>
      </div>
    </div>
    <p>{{text}}</p>
  </router-link>
</template>

<script>
export default {
  props: ['text', 'to', 'type'],
  name: 'Btn',
  data() {
    return {
    };
  },
};
</script>
