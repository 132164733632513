<template>
  <section id="contact" class="main_padding">
    <div class="bg"></div>
    <div class="decor_triangle"></div>
    <div class="inner w1200 txt-center">
      <h2 class="txt-theme txt-an">
        <span class="txt-bold">CONTACT</span>&nbsp;
        <span>US</span>
      </h2>
      <h6 class="txt-bold">
        聯絡我們
      </h6>
      <p>
        如您有任何意見或疑問，敬請不吝指教。我們將盡力為您解答。<br>
        為了加快回覆速度，請依照您的需求，並填寫相關必填欄位，感謝您的配合！
      </p>
      <div class="btnset">
        <Btn
          :to="{
            name: 'Contact',
            params: {
              type: 'company',
            },
          }"
          :type="2"
          :text="'Contact Us'"/>
        <Btn
          :to="{
            name: 'Contact',
            params: {
              type: 'recruit',
            },
          }"
          :type="1"
          :text="'Join Us'"/>
      </div>
    </div>
  </section>
</template>

<script>
import Btn from '@/components/public/Btn.vue';

export default {
  name: 'Contact',
  components: {
    Btn,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
};
</script>
