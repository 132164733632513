var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main_padding",attrs:{"id":"contact"}},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"decor_triangle"}),_c('div',{staticClass:"inner w1200 txt-center"},[_vm._m(0),_c('h6',{staticClass:"txt-bold"},[_vm._v(" 聯絡我們 ")]),_vm._m(1),_c('div',{staticClass:"btnset"},[_c('Btn',{attrs:{"to":{
          name: 'Contact',
          params: {
            type: 'company',
          },
        },"type":2,"text":'Contact Us'}}),_c('Btn',{attrs:{"to":{
          name: 'Contact',
          params: {
            type: 'recruit',
          },
        },"type":1,"text":'Join Us'}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"txt-theme txt-an"},[_c('span',{staticClass:"txt-bold"},[_vm._v("CONTACT")]),_vm._v("  "),_c('span',[_vm._v("US")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" 如您有任何意見或疑問，敬請不吝指教。我們將盡力為您解答。"),_c('br'),_vm._v(" 為了加快回覆速度，請依照您的需求，並填寫相關必填欄位，感謝您的配合！ ")])}]

export { render, staticRenderFns }