<template>
  <section id="about" ref="index_about">
    <div
      class="fixed_bg"
      :class="[
        {fixed: ifFixed},
        {'ab-bottom': ifBottom},
      ]">
        <div class="white_space"></div>
        <div class="w1400 text_box">
          <div class="text">
            <h2 class="txt-theme txt-an">
              <span class="txt-bold">ABOUT </span>
              <span>US</span>
            </h2>
            <h6 class="txt-bold">
              關於日商麒麟技術
            </h6>
            <p>
              日商麒麟技術是工廠建設合作夥伴，從工廠建設的計畫階段到試運轉，全面地協助顧客，是間專業從事食品和製藥工廠的工程技術公司。
            </p>
          </div>
        </div>
    </div>
    <div class="marquee" v-if="newsData.length !== 0">
      <swiper
        class="marquee_swiper"
        ref="marquee_swiper"
        :options="marqueeSwiperOption">
          <swiper-slide
            v-for="(item, i) in newsData"
            :key="`marquee${i}`">
              <router-link
                class="item"
                :to="{
                  name: 'NewsInfo',
                  params: { id: item._id },
                }">
                  <h6 class="txt-theme title">
                    NEWS
                  </h6>
                  <small class="date">
                    {{item.publish_date}}
                  </small>
                  <p class="infotext">
                    {{item.title}}
                  </p>
              </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="inner w1400">
      <div class="text">
        <h2 class="txt-theme">
          <span class="txt-bold txt-an">ABOUT </span>
          <span>US</span>
        </h2>
        <h6 class="txt-bold">
          關於日商麒麟技術
        </h6>
        <p>
          日商麒麟技術是工廠建設合作夥伴，從工廠建設的計畫階段到試運轉，全面地協助顧客，是間專業從事食品和製藥工廠的工程技術公司。
        </p>
      </div>
      <div class="itembox">
        <router-link
          v-for="(item, i) in indexAboutData"
          :key="`indexAbout${i}`"
          :to="item.route"
          class="item">
          <figure
            class="item_pic"
            :style="
              `background-image: url(${item.pic})`
            ">
          </figure>
          <div class="item_text txt-white">
            <div class="left">
              <h6 class="txt-bold">{{item.titleEn}}</h6>
              <p>{{item.title}}</p>
            </div>
            <div class="arrow-circle"></div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { indexAboutData } from '@/utils/const';
import requestApi from '@/lib/http/index';

export default {
  name: 'About',
  data() {
    return {
      indexAboutData,
      ifFixed: false,
      ifBottom: false,
      newsData: [],
      marqueeSwiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#about .marquee .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed: {
    ...mapState([
      'scrollDistence',
    ]),
  },
  watch: {
    scrollDistence() {
      if (this.scrollDistence >= this.$refs.index_about.offsetTop) {
        this.ifFixed = true;
      } else {
        this.ifFixed = false;
      }

      if (
        this.scrollDistence
        >= this.$refs.index_about.offsetTop
        + this.$refs.index_about.clientHeight
        - window.innerHeight
        - 30
      ) {
        this.ifBottom = true;
      } else {
        this.ifBottom = false;
      }
    },
  },
  methods: {
    async getNews() {
      const result = await requestApi(
        'news.index',
        {
          limit: 3,
          filter: { status: 'Y', index_status: 'Y' },
          sort: { _created: -1 },
        },
      );
      console.log(result);

      this.newsData = [...result.entries];
    },
  },
  created() {
    this.getNews();
  },
};
</script>
